import { useState, useEffect } from 'react';
import { STREAM_API_KEY, STREAM_APP_ID } from 'constants.js';
import { useBranch } from 'baobab-react/hooks';
import * as stream from 'getstream';
import { fetchActivityFeed } from 'state/activity/actions';

let STREAM_CLIENT;

const useStreamNotificationFeed = ({
  isOpenGlobal,
  fetchDefault,
  feedId,
  useActivityFeed,
} = {}) => {
  const [notificationFeedClient, setNotificationFeedClient] =
    useState(undefined);
  const [feedStatus, setFeedStatus] = useState({ loading: true });
  const [feed, setFeedNotificationCounter] = useState([]);
  const [feedList, setFeedList] = useState([]);
  const [feedFlatList, setFeedFlatList] = useState([]);
  const { session } = useBranch({
    session: ['authentication', 'session'],
  });

  const sessionNotification = session && session.notification;
  const sessionActivity = session && session.activity;

  useEffect(() => {
    async function getFeedFromApi(id) {
      setFeedStatus({ loading: true });
      const ff = await fetchActivityFeed(id);
      setFeedFlatList(ff.results);
      setFeedStatus({ loading: false, error: ff.error });
    }
    if (sessionNotification && sessionActivity) {
      try {
        const client =
          STREAM_CLIENT || stream.connect(STREAM_API_KEY, null, STREAM_APP_ID);
        if (!STREAM_CLIENT) {
          STREAM_CLIENT = client;
        }

        if (useActivityFeed) {
          const activityFeed = client.feed(
            sessionActivity.stream,
            sessionActivity.feed,
            sessionActivity.token
          );
          setNotificationFeedClient({ client: activityFeed, flat: true });
        } else if (!feedId) {
          const notificationFeed = client.feed(
            sessionNotification.stream,
            sessionNotification.feed,
            sessionNotification.token
          );
          setNotificationFeedClient({ client: notificationFeed, flat: false });
        } else {
          getFeedFromApi(feedId);
        }
      } catch (err) {
        setFeedStatus({ loading: false, error: err.message });
        console.error('Error Loading Notification Stream');
      }
    }
  }, [feedId, sessionActivity, sessionNotification, useActivityFeed]);

  async function activityFeedNotificationCounterSuccess({ results = [] } = {}) {
    setFeedNotificationCounter((prevFeed) => [...prevFeed, ...results]);
    return true;
  }

  function streamFailCallback(data) {
    console.log(data, 'error in stream feed');
  }

  async function flatActivityFeedSuccess({ results = [] } = {}) {
    setFeedFlatList(results);
    return true;
  }

  async function streamUserActivity({ results = [] } = {}) {
    setFeedList(results);
    return true;
  }

  async function updateNotificationCounter({ results = [] } = {}) {
    if (results[0]) {
      setFeedNotificationCounter((prevFeed) => [
        ...prevFeed,
        {
          verb: results[0].verb,
          actor_count: 1,
          activity_count: 1,
          is_seen: false,
          activities: results,
        },
      ]);
    }
    return true;
  }

  useEffect(() => {
    // Get Header Notification Feed
    if (notificationFeedClient && !notificationFeedClient.flat) {
      notificationFeedClient.client
        .get()
        .then(activityFeedNotificationCounterSuccess, streamFailCallback);
      notificationFeedClient.client.subscribe((d) =>
        updateNotificationCounter({ results: d.new })
      );
    }
  }, [notificationFeedClient]);

  useEffect(() => {
    // Get Flat Activity Notification Feed
    if (notificationFeedClient && notificationFeedClient.flat) {
      setFeedStatus({ flatActivityloading: true });
      notificationFeedClient.client
        .get({ limit: 60, mark_seen: true })
        .then(flatActivityFeedSuccess, streamFailCallback)
        .then(() => setFeedStatus({ flatActivityloading: false }));
    }
  }, [notificationFeedClient]);

  useEffect(() => {
    // Get Grouped Notification Feed
    if (
      notificationFeedClient &&
      !notificationFeedClient.flat &&
      (isOpenGlobal || fetchDefault)
    ) {
      setFeedStatus({ loading: true });
      notificationFeedClient.client
        .get({ limit: 40, mark_seen: true })
        .then(streamUserActivity, streamFailCallback)
        .then(() => setFeedStatus({ loading: false }));
    }
  }, [fetchDefault, isOpenGlobal, notificationFeedClient]);

  return {
    feedList,
    feed, // this is counter for badge in notification dropdown
    feedFlatList,
    feedStatus,
    loading: feedStatus.loading,
    error: feedStatus.error,
    resetNotifications: () => setFeedNotificationCounter([]),
  };
};

export default useStreamNotificationFeed;
