import React, { useCallback, useState } from 'react';
import { SM } from '@zendeskgarden/react-typography';
import { Formik } from 'formik';
import { FormInput, Form } from 'theme/Form';
import { Row, Col } from '@zendeskgarden/react-grid';
import { closeSidebar } from 'state/sidebars/actions';
import { FullFormFieldWrapper } from 'theme/AuthForms.styles';
import { useBranch } from 'baobab-react/hooks';
import { get, map, filter, head } from 'lodash';
import { Button } from 'theme/Button';
import { convertFromRaw, EditorState } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import createLinkifyPlugin from 'draft-js-linkify-plugin';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import createLinkPlugin from 'draft-js-anchor-plugin';
import {
  createActionItem,
  updateActionItem,
  deleteActionItem,
} from 'state/crm/actions';
import {
  getRawDraft,
  trimPastedText,
  mapKeyToEditorCommand,
  shouldHidePlaceholder,
} from 'utility/draftjsUtilities';
import DatePicker from 'components/DatePicker/DatePicker';
import moment from 'moment';
import CompanyLookupV2 from 'components/CompanyLookup/CompanyLookupV2';
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  UnorderedListButton,
  OrderedListButton,
} from 'draft-js-buttons';
import AutoCompleteFormItem from 'components/MultiTypeForm/components/MultiSelectFormItem/AutoCompleteFormItem';
import { variables } from 'theme/variables';
import PropTypes from 'prop-types';
import { actionItemFormDataShape, editorStateShape } from 'propTypesObjects.js';
import { getRole } from 'utility/hasAccount';
import Flex from 'styled-flex-component';
import InPageOverlay from 'components/InPageOverlay/InPageOverlay';
import { Wrapper, WrapperInner } from './CampaignEmailSettingsForm.styles';

import 'draft-js/dist/Draft.css';

const { color_red_400: colorRed400, custom_red: customRed } = variables;

function ActionItemForm({ disabled, actionitemId, data = {}, onSuccess }) {
  const { value: role } = getRole() || {};
  const [deletingStatus, setDeletingStatus] = useState({});
  const [localActionitemId, setLocalActionitemId] = useState(actionitemId);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [editorState, setEditorState] = useState(
    data.json?.draft
      ? EditorState.createWithContent(convertFromRaw(data.json.draft))
      : EditorState.createEmpty()
  );

  const { rfqMeta, clientId, company } = useBranch({
    rfqMeta: ['requestForQuote', 'meta', 'result'],
    clientId: [
      'authentication',
      'session',
      'roles',
      role,
      'data',
      'company',
      'id',
    ],
    company: ['authentication', 'session', 'roles', role, 'data', 'company'],
  });
  const priorityOptions = get(rfqMeta, 'priority');
  const statusOptions = get(rfqMeta, 'actionitem_statuses');

  const defaultPriority = head(
    map(filter(priorityOptions, ['name', 'Medium']), (item) => ({
      value: item.id,
      label: item.name,
    }))
  );
  const defaultStatus = head(
    map(filter(statusOptions, ['name', 'Not Started']), (item) => ({
      value: item.id,
      label: item.name,
    }))
  );

  const memoSubmitActionItem = useCallback(
    async (values, { setSubmitting, setStatus }) => {
      setSubmitting(true);
      const outData = { ...values, json: getRawDraft(editorState) };
      outData.priority = outData.priority.value;
      outData.actionitem_status = outData.actionitem_status.value;
      outData.assignee = outData.assignee.value;
      outData.deadline = moment(outData.deadline).format();
      // only send content object on create not update
      if (data.contentType) {
        outData.content_type = data.contentType;
      }
      if (data.objectId) {
        outData.object_id = data.objectId;
      }

      const saveActionItemResult = localActionitemId
        ? await updateActionItem(localActionitemId, outData)
        : await createActionItem(outData);

      const { error } = saveActionItemResult;
      if (error) {
        setStatus({ error });
        setSubmitting(false);
      } else {
        setStatus({ error });
        setLocalActionitemId(saveActionItemResult.id);
        if (onSuccess) {
          // We await onSuccess here to keep the sidebar open during the delay implemented in MultiDetailsActionItemsTab
          await onSuccess({
            newActionItem: !localActionitemId
              ? saveActionItemResult
              : undefined,
          });
        }
        setSubmitting(false);
        closeSidebar();
      }
    },
    [data.contentType, data.objectId, editorState, localActionitemId, onSuccess]
  );

  const memoDeleteActionItem = useCallback(async () => {
    const { id } = data;
    setDeletingStatus({ loading: true });
    const result = await deleteActionItem(id);
    if (result.error) {
      setDeletingStatus({ error: result.error });
    } else {
      if (onSuccess) {
        await onSuccess({ deletedActionItem: data });
      }
      setDeletingStatus({ loading: false });
      closeSidebar();
    }
  }, [data, onSuccess]);

  return (
    <>
      <Formik
        initialValues={{
          title: data.title || '',
          assignee: data.assignee_id
            ? { value: data.assignee_id, label: data.assignee_name }
            : '',
          priority: data.priority
            ? { value: data.priority, label: data.priority_name }
            : defaultPriority,
          actionitem_status: data.actionitem_status
            ? {
                value: data.actionitem_status,
                label: data.actionitem_status_name,
              }
            : defaultStatus,
          deadline: data.deadline ? new Date(data.deadline) : '',
        }}
        onSubmit={memoSubmitActionItem}
      >
        {(props) => (
          <FormRendered
            dirty={props.dirty}
            errors={props.errors}
            handleBlur={props.handleBlur}
            handleChange={props.handleChange}
            handleReset={props.handleReset}
            handleSubmit={props.handleSubmit}
            initialValues={props.initialValues}
            isSubmitting={props.isSubmitting}
            isValid={props.isValid}
            isValidating={props.isValidating}
            registerField={props.registerField}
            resetForm={props.resetForm}
            setError={props.setError}
            setErrors={props.setErrors}
            setFieldError={props.setFieldError}
            setFieldTouched={props.setFieldTouched}
            setFieldValue={props.setFieldValue}
            setFormikState={props.setFormikState}
            setStatus={props.setStatus}
            setSubmitting={props.setSubmitting}
            setTouched={props.setTouched}
            setValues={props.setValues}
            status={props.status}
            submitCount={props.submitCount}
            submitForm={props.submitForm}
            touched={props.touched}
            unregisterField={props.unregisterField}
            validateField={props.validateField}
            validateForm={props.validateForm}
            validateOnBlur={props.validateOnBlur}
            validateOnChange={props.validateOnChange}
            values={props.values}
            actionitemId={localActionitemId}
            deletingStatus={deletingStatus}
            onDelete={memoDeleteActionItem}
            disabled={disabled}
            priorityOptions={priorityOptions}
            statusOptions={statusOptions}
            {...{
              editorState,
              setEditorState,
              company,
              clientId,
              setShowDeleteConfirmation,
            }}
          />
        )}
      </Formik>
      <InPageOverlay
        portal
        showHeader
        title="Confirm Delete Action Item"
        onClose={() => setShowDeleteConfirmation(false)}
        open={showDeleteConfirmation}
        style={{
          zIndex: 9999,
        }}
      >
        <Flex
          column
          style={{
            maxWidth: '300px',
            margin: '20px',
          }}
        >
          <div
            style={{
              marginBottom: '20px',
            }}
          >
            Are you sure you want to delete this Action Item?
          </div>
          <Flex
            alignCenter
            justifyCenter
            style={{
              marginBottom: '20px',
            }}
          >
            <Button
              primary
              loading={deletingStatus.loading}
              onClick={memoDeleteActionItem}
            >
              Delete
            </Button>
            <Button
              secondary
              onClick={() => setShowDeleteConfirmation(false)}
              style={{
                marginLeft: '10px',
              }}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      </InPageOverlay>
    </>
  );
}

ActionItemForm.propTypes = {
  disabled: PropTypes.bool,
  actionitemId: PropTypes.number,
  data: actionItemFormDataShape,
  onSuccess: PropTypes.func,
  dirty: PropTypes.bool,
  errors: PropTypes.string,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleReset: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.shape({}),
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
  isValidating: PropTypes.bool,
  registerField: PropTypes.shape({}),
  resetForm: PropTypes.bool,
  setError: PropTypes.bool,
  setErrors: PropTypes.bool,
  setFieldError: PropTypes.bool,
  setFieldTouched: PropTypes.bool,
  setFieldValue: PropTypes.bool,
  setFormikState: PropTypes.bool,
  setStatus: PropTypes.bool,
  setSubmitting: PropTypes.bool,
  setTouched: PropTypes.bool,
  setValues: PropTypes.bool,
  status: PropTypes.string,
  submitCount: PropTypes.func,
  submitForm: PropTypes.func,
  touched: PropTypes.bool,
  unregisterField: PropTypes.shape({}),
  validateField: PropTypes.bool,
  validateForm: PropTypes.bool,
  validateOnBlur: PropTypes.bool,
  validateOnChange: PropTypes.bool,
  values: PropTypes.shape({}),
};

function FormRendered({
  actionitemId,
  deletingStatus,
  disabled,
  editorState,
  setEditorState,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  status,
  setFieldValue,
  priorityOptions,
  statusOptions,
  clientId,
  setShowDeleteConfirmation,
}) {
  return (
    <Form size="large" onSubmit={handleSubmit}>
      <Row>
        <Col size="12" sm="6">
          <FullFormFieldWrapper spacer spacing="0px">
            <SM bold navy scaled tag="span">
              Action Item Name <span style={{ color: customRed }}> * </span>
            </SM>
            <FormInput
              autoFocus
              type="text"
              id="title"
              name="title"
              disabled={disabled}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.title}
              showMessage
              error={errors.name && touched.name && errors.name}
              placeholder="Action Item Name"
            />
          </FullFormFieldWrapper>
        </Col>

        <Col size="12" sm="6">
          <FullFormFieldWrapper spacer spacing="0px">
            <SM bold navy scaled tag="span">
              Assignee <span style={{ color: customRed }}> * </span>
            </SM>
            <CompanyLookupV2
              searchType="user"
              placeholder="Search Employee"
              clientId={clientId}
              style={{ marginTop: 10 }}
              selectedItem={values.assignee}
              setSelectedValue={(value) => {
                setFieldValue('assignee', value);
              }}
            />
          </FullFormFieldWrapper>
        </Col>
      </Row>
      <Row>
        <Col size="12" sm="6">
          <FullFormFieldWrapper spacer spacing="0px">
            <SM bold navy scaled tag="span">
              Priority
            </SM>
            <AutoCompleteFormItem
              defaultValue="Select Priority"
              style={{ marginTop: 10 }}
              optionValues={map(priorityOptions, (item) => ({
                value: item.id,
                label: item.name,
              }))}
              selectedItem={values.priority}
              setSelectedValue={(value) => {
                setFieldValue('priority', value);
              }}
              active={values.priority !== ''}
            />
          </FullFormFieldWrapper>
        </Col>
        <Col size="12" sm="6">
          <FullFormFieldWrapper spacer spacing="0px">
            <SM bold navy scaled tag="span">
              Deadline <span style={{ color: customRed }}> * </span>
            </SM>
            <div style={{ marginTop: '10px' }}>
              <DatePicker
                minDate
                validation={touched.deadline && errors.deadline ? 'error' : ''}
                date={values.deadline || ''}
                onChange={(value) => {
                  setFieldValue('deadline', value);
                }}
              />
            </div>
          </FullFormFieldWrapper>
        </Col>
      </Row>
      <Row>
        <Col size="12" sm="6">
          <FullFormFieldWrapper spacer spacing="0px">
            <SM bold navy scaled tag="span">
              Status
            </SM>
            <AutoCompleteFormItem
              defaultValue="Select Status"
              style={{ marginTop: 10 }}
              optionValues={map(statusOptions, (item) => ({
                value: item.id,
                label: item.name,
              }))}
              selectedItem={values.actionitem_status}
              setSelectedValue={(value) => {
                setFieldValue('actionitem_status', value);
              }}
              active={values.actionitem_status !== ''}
            />
          </FullFormFieldWrapper>
        </Col>
      </Row>

      <Row>
        <Col size="12">
          <FullFormFieldWrapper spacer spacing="0px">
            <SM bold navy scaled>
              Action Item Description
            </SM>
            <InputContainer
              disabled={disabled}
              {...{ editorState, setEditorState }}
            />
          </FullFormFieldWrapper>
        </Col>
      </Row>

      <Row style={{ marginBottom: '10px' }}>
        <Col alignCenter md={6}>
          <Button
            loading={isSubmitting || undefined}
            type="submit"
            disabled={disabled}
            buttonStatus
            error={status && status.error}
            primary
          >
            Save Action Item
          </Button>
        </Col>
        {actionitemId && (
          <Col alignCenter justifyEnd md={6}>
            <Button
              color={colorRed400}
              link
              loading={deletingStatus.loading}
              buttonStatus
              error={deletingStatus.error}
              onClick={() => setShowDeleteConfirmation(true)}
            >
              Delete Action Item
            </Button>
          </Col>
        )}
      </Row>
      {status && status.error && (
        <Row>
          <Col alignCenter justifyCenter>
            <div>
              <SM error validation="error">
                {status.error}
              </SM>
            </div>
          </Col>
        </Row>
      )}
    </Form>
  );
}

FormRendered.propTypes = {
  actionitemId: PropTypes.number,
  deletingStatus: PropTypes.shape({
    error: PropTypes.bool,
    loading: PropTypes.bool,
  }),
  disabled: PropTypes.bool,
  editorState: PropTypes.shape({}),
  setEditorState: PropTypes.func,
  values: PropTypes.shape({
    actionitem_status: PropTypes.shape({}),
    priority: PropTypes.shape({}),
    deadline: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    employee: PropTypes.string,
    title: PropTypes.string,
    assignee: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  }),
  errors: PropTypes.shape({
    name: PropTypes.string,
    deadline: PropTypes.string,
  }),
  touched: PropTypes.shape({
    name: PropTypes.string,
    deadline: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  }),
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  setFieldValue: PropTypes.func,
  priorityOptions: PropTypes.arrayOf(PropTypes.shape({})),
  statusOptions: PropTypes.arrayOf(PropTypes.shape({})),
  clientId: PropTypes.number,
  setShowDeleteConfirmation: PropTypes.func,
};

export default ActionItemForm;

export class InputContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rendered: true,
    };
  }

  componentDidMount() {
    this.linkifyPlugin = createLinkifyPlugin();
    this.staticToolbarPlugin = createToolbarPlugin();
    this.linkPlugin = createLinkPlugin();

    this.plugins = [
      this.linkifyPlugin,
      this.staticToolbarPlugin,
      this.linkPlugin,
    ];
    // force re render
    this.setState({ rendered: true });
  }

  onEditorStateChange = (editorState) => {
    const { setEditorState, disabled } = this.props;
    if (disabled) {
      return;
    }
    setEditorState(editorState);
  };

  render() {
    const { editorState, setEditorState, placeholder, disableToolbar } =
      this.props;
    const { linkPlugin } = this;
    const { rendered } = this.state;
    const Toolbar =
      this.staticToolbarPlugin && this.staticToolbarPlugin.Toolbar;
    return (
      <Wrapper
        className="editor-input-container-wrapper"
        onClick={(e) => e.stopPropagation()}
      >
        <WrapperInner hidePlaceholder={shouldHidePlaceholder(editorState)}>
          {rendered && this.plugins ? (
            <div>
              <Editor
                placeholder={placeholder || 'Enter Action Item Description'}
                editorState={editorState}
                onChange={this.onEditorStateChange}
                plugins={this.plugins}
                formatPastedText={trimPastedText}
                keyBindingFn={(e) =>
                  mapKeyToEditorCommand(e, editorState, setEditorState)
                }
                ref={(element) => {
                  // TODO:
                  // eslint-disable-next-line react/no-unused-class-component-methods
                  this.editor = element;
                }}
                spellCheck
              />
              {!disableToolbar ? (
                <Toolbar>
                  {(externalProps) => (
                    <div>
                      <BoldButton
                        theme={externalProps.theme}
                        onOverrideContent={externalProps.onOverrideContent}
                        setEditorState={externalProps.setEditorState}
                        getEditorState={externalProps.getEditorState}
                      />
                      <ItalicButton
                        theme={externalProps.theme}
                        onOverrideContent={externalProps.onOverrideContent}
                        setEditorState={externalProps.setEditorState}
                        getEditorState={externalProps.getEditorState}
                      />
                      <UnderlineButton
                        theme={externalProps.theme}
                        onOverrideContent={externalProps.onOverrideContent}
                        setEditorState={externalProps.setEditorState}
                        getEditorState={externalProps.getEditorState}
                      />
                      <UnorderedListButton
                        theme={externalProps.theme}
                        onOverrideContent={externalProps.onOverrideContent}
                        setEditorState={externalProps.setEditorState}
                        getEditorState={externalProps.getEditorState}
                      />
                      <OrderedListButton
                        theme={externalProps.theme}
                        onOverrideContent={externalProps.onOverrideContent}
                        setEditorState={externalProps.setEditorState}
                        getEditorState={externalProps.getEditorState}
                      />
                      <linkPlugin.LinkButton
                        theme={externalProps.theme}
                        onOverrideContent={externalProps.onOverrideContent}
                        setEditorState={externalProps.setEditorState}
                        getEditorState={externalProps.getEditorState}
                      />
                    </div>
                  )}
                </Toolbar>
              ) : null}
            </div>
          ) : null}
        </WrapperInner>
      </Wrapper>
    );
  }
}

InputContainer.propTypes = {
  editorState: editorStateShape,
  disabled: PropTypes.bool,
  setEditorState: PropTypes.func,
  placeholder: PropTypes.string,
  disableToolbar: PropTypes.func,
};
