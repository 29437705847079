import fetchJson from 'utility/fetchJson';
import queryString from 'query-string';
import { isEmpty } from 'lodash';
import { getValuesForRequest } from 'utility/reverseUrlFilters';
import { BASE } from '../../constants';

export async function getSupplyInterestsGroupItems(groupId) {
  const response = await fetchJson(
    `${BASE}/api/supply/group/${groupId}/items/`,
    {
      method: 'GET',
    }
  );
  return response;
}

export async function getSupplyInterestsItems(
  { criteria },
  { sorting = {}, filters = {}, pagination = {} }
) {
  const newFilters = isEmpty(filters)
    ? getValuesForRequest('supplyOpportunities')
    : filters;
  let newCriteria = criteria;
  if (!criteria || isEmpty(criteria)) {
    newCriteria = {};
  }

  const body = {
    ...newFilters,
    ...pagination,
    ...sorting,
    ...newCriteria,
  };
  const response = await fetchJson(`${BASE}/api/supply/item/list/`, {
    method: 'POST',
    body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
  });
  return response;
}

export async function getSupplyInterestsItemsForAgGrid(
  { criteria },
  { sorting = {}, filters = {}, pagination = {} }
) {
  let newCriteria = criteria;
  if (!criteria || isEmpty(criteria)) {
    newCriteria = {};
  }

  const body = {
    ...filters,
    ...pagination,
    ...sorting,
    ...newCriteria,
  };
  const response = await fetchJson(`${BASE}/api/supply/item/list/`, {
    method: 'POST',
    body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
  });
  return response;
}

export async function getSupplyInterestsGroups(
  { criteria },
  { sorting = {}, filters = {}, pagination = {} } = {}
) {
  const newFilters = isEmpty(filters)
    ? getValuesForRequest('supplyOpportunities')
    : filters;

  let newCriteria = criteria;
  if (!criteria || isEmpty(criteria)) {
    newCriteria = {};
  }

  const body = { ...newFilters, ...pagination, ...sorting, ...newCriteria };
  const response = await fetchJson(`${BASE}/api/supply/group/list/`, {
    method: 'POST',
    body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
  });
  return response;
}

export async function getSupplyInterestsGroupsForAgGrid(
  { criteria },
  { sorting = {}, filters = {}, pagination = {} } = {}
) {
  const body = { ...filters, ...pagination, ...sorting, ...criteria };
  const response = await fetchJson(`${BASE}/api/supply/group/list/`, {
    method: 'POST',
    body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
  });
  return response;
}

export async function getPossibleSupplyGroupTransitions(id) {
  const response = await fetchJson(
    `${BASE}/api/supply/group/${id}/possible-transitions/`,
    {
      method: 'GET',
    }
  );
  return response;
}

export async function getPossibleSupplyItemTransitions(id) {
  const response = await fetchJson(
    `${BASE}/api/supply/item/${id}/possible-transitions/`,
    {
      method: 'GET',
    }
  );
  return response;
}

export async function getSupplyInterestDetails(opportunityId) {
  const response = await fetchJson(
    `${BASE}/api/supply/item/${opportunityId}/`,
    {
      method: 'GET',
    }
  );
  return response;
}

export async function createSupplyOpportunity(data) {
  const response = await fetchJson(`${BASE}/api/supply/item/facilitator/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function patchSupplyOpportunity(opportunityId, data) {
  const response = await fetchJson(
    `${BASE}/api/supply/item/facilitator/${opportunityId}/`,
    {
      method: 'PATCH',
      body: JSON.stringify(data),
    }
  );
  return response;
}

// PERSONNEL
export async function geteSupplyPersonnel({
  supply_opportunity: supplyOpportunity,
}) {
  const response = await fetchJson(
    `${BASE}/api/supply/personnel/supply/${supplyOpportunity}/`,
    {
      method: 'GET',
    }
  );
  return response;
}

export async function getPersonnelMeta() {
  const response = await fetchJson(
    `${BASE}/api/supply/supply/personnel-role/`,
    {
      method: 'GET',
    }
  );
  return response;
}

export async function createPersonnel(data) {
  const response = await fetchJson(`${BASE}/api/supply/personnel/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function updatePersonnelItem(id, data) {
  const response = await fetchJson(`${BASE}/api/supply/personnel/${id}/`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  });
  return response;
}

export async function deletePersonnelItem(id) {
  const response = await fetchJson(`${BASE}/api/supply/personnel/${id}/`, {
    method: 'DELETE',
  });
  return response;
}

export async function getPersonnel({ userId, supply, clientId }) {
  const stringified = queryString.stringify({ userId, supply, clientId });
  const response = await fetchJson(
    `${BASE}/api/supply/personnel/?${stringified}`,
    {
      method: 'GET',
    }
  );
  return response;
}

export async function getSupplyItemStatusLog({ id, paginationUrl }) {
  const response = await fetchJson(
    paginationUrl || `${BASE}/api/supply/item/transitions/${id}/`,
    {
      method: 'GET',
    }
  );
  return response;
}

/// GROUP

export async function getSupplyInterestGroupDetails(id) {
  const response = await fetchJson(`${BASE}/api/supply/group/${id}/`, {
    method: 'GET',
  });
  return response;
}

export async function getGroupStatusLog({ id, paginationUrl }) {
  const response = await fetchJson(
    paginationUrl || `${BASE}/api/supply/group/transitions/${id}/`,
    {
      method: 'GET',
    }
  );
  return response;
}

// Field Inspection
export async function submitFieldInspection(data) {
  const response = await fetchJson(`${BASE}/api/supply/item/inspection/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}
