import { filter, reduce, isArray, omit, get } from 'lodash';
import * as customerEffects from 'state/customers/effects';
import asyncTreeRequester from 'utility/asyncTreeRequester';
import {
  validateOnSave,
  addInterestItem,
} from 'state/requestForQuote/actions_items';
import submitItemsPreparer from 'state/requestForQuote/preparer_items';
import tree from 'state';
import {
  getSupplyInterestsItems,
  getSupplyInterestsGroupItems,
} from 'state/supplyInterests/effects';
import * as effects from './effects';

const sellersApplicationCursor = tree.select(['sellersApplication']);
const supplyInterestCursor = tree.select(['supplyInterests']);

// HELPERS
function makeArrayValues(result) {
  return {
    ...result,
    metallurgy_other: result.metallurgy_other && [result.metallurgy_other],
    metallurgy_group: result.metallurgy_group && [result.metallurgy_group],
    metallurgy: result.metallurgy && [result.metallurgy],
    end_finish: result.end_finish && [result.end_finish],
    end_finish_other: result.end_finish_other && [result.end_finish_other],
    end_finish_category: result.end_finish_category && [
      result.end_finish_category,
    ],
    end_finish_thread_type: result.end_finish_thread_type && [
      result.end_finish_thread_type,
    ],
    end_finish_licensee: result.end_finish_licensee && [
      result.end_finish_licensee,
    ],
  };
}

export async function submitSupplyItem(supplyItemId, data) {
  const result = await asyncTreeRequester({
    func: effects.transitionSupply.bind(this, supplyItemId, 'new', data),
    handleResult: true,
  });
  if (!get(result, 'error')) {
    getSupplyInterestsItems(); // refresh supply opportunities
  }
  return result;
}

export async function getSupplyGroupItems(groupId, options = {}) {
  let values = {};
  if (groupId) {
    values = await asyncTreeRequester({
      func: getSupplyInterestsGroupItems.bind(this, groupId),
      cursor: sellersApplicationCursor,
      handleResult: true,
    });
  }

  if (!values.error) {
    const itemFilterOptions = {
      alloy: {
        multiple: false,
      },
      end_finish: {
        multiple: false,
      },
    };
    let items;
    if (values && values.length) {
      items = reduce(
        values,
        (accum, item) => {
          const modItem = makeArrayValues(item);
          const _accum = {
            ...accum,
            ...addInterestItem({
              ...options,
              seed: modItem,
              initial: true,
              itemFilterOptions,
            }),
          };
          return _accum;
        },
        {}
      );
    } else {
      items = addInterestItem({ ...options, initial: true, itemFilterOptions });
    }
    sellersApplicationCursor.set(['itemSelection'], items);
  }
}

export async function saveSupplyItem({
  id,
  customer,
  itemData,
  supply_opportunity: supplyOpportunity,
}) {
  const itemSelectionId = id;
  const errors = validateOnSave({
    itemSelectionId,
    ignoreTechnicalDetails: true,
    requiredFields: {
      category: true,
      od: true,
      weight_per_foot: true,
      alloy: true,
      min_yield: true,
      end_finish: true,
    },
  });

  if (!errors.length) {
    const newItems = sellersApplicationCursor.get(['itemSelection']);
    const item = newItems[itemSelectionId];
    let preparedData = submitItemsPreparer(item);
    preparedData = reduce(
      preparedData,
      (acc, v, k) => {
        acc[k] = isArray(v) ? v[0] || undefined : v;
        return acc;
      },
      {}
    );

    if (customer) {
      preparedData.user = customer;
    }

    preparedData = omit(preparedData, [
      'lengths_min',
      'lengths_max',
      'technical_details_confirmed',
      'item_number',
    ]);
    preparedData = {
      ...preparedData,
      ...itemData,
      supply_opportunity: supplyOpportunity,
      customer,
    };

    const result = await asyncTreeRequester({
      func: !item.temp
        ? effects.updateSupplyItem.bind(this, id, preparedData)
        : effects.saveSupplyItem.bind(this, preparedData),
      cursor: sellersApplicationCursor,
      path: ['itemSelection', itemSelectionId],
      isSaving: true,
      handleResult: true,
      delayedLoading: 400,
    });

    const current = sellersApplicationCursor.get([
      'itemSelection',
      itemSelectionId,
    ]);
    if (!result || (result && result.error)) {
      if (current) {
        sellersApplicationCursor.merge(['itemSelection', itemSelectionId], {
          error: result.error,
        });
      }
      return { error: result.error };
    }
    sellersApplicationCursor.unset(['itemSelection', itemSelectionId]);
    sellersApplicationCursor.set(['itemSelection', result.id], {
      ...current,
      dirty: false,
      temp: false,
      saved: true,
      original: result,
      id: result.id,
      name: result.name,
      error: null,
    });
    sellersApplicationCursor.set(['itemDetails', 'result'], result);
    return result;
  }
  return { error: true };
}

export async function removeSupplyItem(id, temp, replaceWithDefaultItem) {
  if (
    window.confirm(
      'Are you sure you want to remove this item? It cannot be undone.'
    )
  ) {
    const list = sellersApplicationCursor.get('itemSelectionArray');
    if (temp) {
      sellersApplicationCursor.set(
        'itemSelectionArray',
        filter(list, (item) => item !== id)
      );
      sellersApplicationCursor.unset(['itemSelection', id]);
      return { success: true, id };
    }
    const newItems = sellersApplicationCursor.get(['itemSelection']);
    const serverId = get(newItems[id], 'id') || id; // we want to get the actual item id not the local reference

    const result = await asyncTreeRequester({
      func: effects.deleteSupplyItem.bind(this, serverId),
      cursor: sellersApplicationCursor,
      path: ['itemSelection', id],
      deleting: true,
      handleResult: true,
      delayedLoading: 1000,
    });

    if (!result || (result && result.error)) {
      sellersApplicationCursor.merge(['itemSelection', id], {
        error: result.error,
      });
      tree.commit();
      return { success: false, id };
    }
    sellersApplicationCursor.set(
      'itemSelectionArray',
      filter(list, (item) => item !== id)
    );
    sellersApplicationCursor.unset(['itemSelection', id]);
    tree.commit();
    if (replaceWithDefaultItem) {
      // this is when there is one item from orphans and we want to delete it.
      addInterestItem({ initial: true });
    }
    return { success: true, id };
  }
  return { success: false, id };
}

export function resetSupplyApplication() {
  sellersApplicationCursor.set(['itemSelection'], {});
}

export async function getSupplyCustomer(customerId) {
  try {
    const customer = await customerEffects.getCustomerDetails(customerId);
    sellersApplicationCursor.set(['customer'], customer);
  } catch (err) {
    console.log('getSupplyCustomer error', err);
  }
}

export async function setSupplySourceLead(leadSource, activeGroupId) {
  const _activeGroupId = activeGroupId || null;
  let result;
  if (activeGroupId) {
    try {
      result = await asyncTreeRequester({
        func: effects.updateSupplyGroup.bind(this, {
          id: _activeGroupId,
          data: { lead_source: leadSource.id },
        }),
        handleResult: true,
      });
    } catch (err) {
      return err?.message;
    }
  }

  supplyInterestCursor.set(['group', 'leadSource'], leadSource);
  return result;
}

export async function updateSupplyGroupDetails({ id, data }) {
  const supplyUpdate = await asyncTreeRequester({
    func: effects.updateSupplyGroup.bind(this, { id, data }),
    handleResult: true,
  });
  return supplyUpdate;
}

export async function createSupplyGroupDetails({ data }) {
  const supplyGroup = await asyncTreeRequester({
    func: effects.createSupplyGroup.bind(this, { data }),
    handleResult: true,
  });

  if (supplyGroup?.id) {
    supplyInterestCursor.set(['group', supplyGroup.id], supplyGroup);
  }
  return supplyGroup;
}

export async function transitionSupplyGroup({ supplyItemId, status, data }) {
  const supplyUpdate = await asyncTreeRequester({
    func: effects.transitionSupplyGroup.bind(this, {
      supplyItemId,
      status,
      data,
    }),
    handleResult: true,
  });
  return supplyUpdate;
}

export async function mergeSupplyGroup({ sourceSupplyId, targetSupplyId }) {
  const supplyMerge = await asyncTreeRequester({
    func: effects.mergeSupplyGroup.bind(this, {
      sourceSupplyId,
      targetSupplyId,
    }),
    handleResult: true,
  });
  return supplyMerge;
}

export async function splitSupplyGroup({ supplyGroupId, name, supplyItemIds }) {
  const supplySplit = await asyncTreeRequester({
    func: effects.splitSupplyGroup.bind(this, {
      supplyGroupId,
      name,
      supplyItemIds,
    }),
    handleResult: true,
  });
  return supplySplit;
}
